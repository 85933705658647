/* Main
================================================== */
main {
  padding: 2rem 0 6rem 0;
}

.label-warning {
  display: none;
}

.secondary-colour {
  color: $secondary-colour; // Foodex
}
.tertiary-colour {
  color: $tertiary-colour; // NCS
}
.quaternary-colour {
  color: $quaternary-colour; // Food & Drink
}
.quinary-colour {
  color: $quinary-colour; // Farm Shop
}
.senary-colour {
  color: $senary-colour; // Forecourt
}
.septenary-colour {
  color: $septenary-colour; // TRS
}
