/* Header
================================================== */
header {
  padding: 2rem 0;
}

.header {
  &__logo {
    display: flex;
    justify-content: center;
    img {
      width: 252px;
    }
    a {
      display: inline-flex;
    }
  }
}
