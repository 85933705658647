/* Footer
================================================== */
footer {
  position: relative;
  z-index: 30;
  width: 100%;
  margin-top: 6rem;
  padding: 1rem 0;
  background-color: $primary-colour;
  * {
    text-transform: uppercase;
    color: $white;
  }
  .row {
    align-items: center;
    opacity: 0.8;
  }
}
.footer {
  &__signature {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    @include bp(medium) {
      justify-content: flex-end;
    }
    img {
      margin-left: 1rem;
      width: 14rem;
      @include transition($global-transition);
    }
  }
}
