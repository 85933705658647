/* Components
================================================== */

/* Stats
================================================== */
.stat {
  text-align: center;
  * {
    color: $dark-grey;
    font-weight: 400;
  }
  p {
    font-size: 1.2rem;
  }
  h6 {
    margin: 0 0 0.5rem 0;
    font-size: 3rem;
  }
}

/* Card
================================================== */
.no-gap.wf-columns {
  gap: 0;
}
.card-wrap.wf-column {
  flex: 1 1 0px;
  min-width: 0;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  flex: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
  @include bp(medium) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @include bp(large) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}
.card {
  height: 100%;
  &--height-auto {
    height: auto;
  }
  a {
    position: relative;
    height: 100%;
    display: block;
    padding: 4rem 4rem 9rem;
    text-align: center;
    color: $black;
    border: 4px solid $white;
    -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
    &:hover {
      border-color: $off-white;
      -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.2);
    }
  }
  h4 {
    margin-top: 0.5rem;
  }
  strong {
    display: block;
    margin-bottom: 1rem;
  }
  .button-wrap {
    position: absolute;
    right: 0;
    bottom: 2rem;
    left: 0;
    margin: 2rem auto 0;
    text-align: center;
  }
}
