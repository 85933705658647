/* Navigation
================================================== */
.nav {
  display: none;
  width: auto;
  margin: 0;
  padding: 0;
  background: $white;

  @include bp(mlarge) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  li {
    position: relative;
    margin-bottom: 0;
    text-align: center;
    list-style: none;
    @include bp_max(mlarge) {
      width: auto;
      text-align: center;
    }
    &:last-of-type {
      .mod-menu__sub {
        @include bp(large) {
          right: 0;
        }
        li {
          @include bp(large) {
            text-align: right;
          }
        }
      }
    }
  }

  a {
    display: block;
    padding: 1rem 1.6rem;
    color: #000;
    font-size: 1rem;
    text-decoration: none;
    line-height: 1.2;
  }

  a:hover,
  .current > a,
  .active > a {
    color: $secondary-colour;
  }

  ul {
    display: none;
  }

  .parent {
    > a {
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 10%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 5px 0 5px;
        border-color: #000000 transparent transparent transparent;
      }
    }

    &:hover {
      ul {
        display: flex;
      }
    }

    ul {
      display: none;
      flex-flow: column nowrap;
      list-style: none;
      width: 100%;
      margin: 0;
      padding: 0;

      @include bp(mlarge) {
        position: absolute;
        top: 100%;
        align-items: flex-start;
      }

      li {
        width: 100%;
      }
    }
  }

  .nav-active & {
    display: flex;
    width: 100%;
    height: 100vh;
    position: absolute;
    margin-left: 0;
    left: 0;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-top: 8em;

    @include bp_max(smedium) {
      padding-top: 5em;
    }
  }
}

/* Hamburger / Menu
================================================== */
.nav-button {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  margin: 0 0 0 auto;
  padding: 1rem 0;
  background: transparent;
  outline: 0;
  cursor: pointer;

  p {
    margin: 0;
    padding: 0 2.5rem 0 0;
    color: $body-font-color;
  }

  span,
  span:before,
  span:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    width: 2em;
    height: 4px;
    background: #000;
    @include border-radius(1px);
    @include transition($global-transition);
  }

  span {
    &:before {
      top: -10px;
    }
    &:after {
      top: auto;
      bottom: -10px;
    }
  }

  .nav-active & {
    span {
      background: transparent;
      &:before,
      &:after {
        top: 0;
        background: #000;
      }
      &:before {
        @include rotate(45);
      }
      &:after {
        @include rotate(-45);
      }
    }
  }

  @include bp(mlarge) {
    display: none;
  }
}

button.nav-button {
  background: transparent;
}
