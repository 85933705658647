.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"],
.com-baforms-wrapper .ba-form-submit-btn {
  position: relative;
  z-index: 1;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;
  padding: 1rem 1.5rem;
  background: rgb(47, 181, 179);
  background: linear-gradient(
    90deg,
    rgba(47, 181, 179, 1) 0%,
    rgba(37, 140, 138, 1) 100%
  );
  border: 0;
  color: $white;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  line-height: normal;
  font-family: $primary-font;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  outline: none;
  border-radius: 0;
  @include transition($global-transition);
  -webkit-appearance: none;

  &:hover {
    color: $white;
    border: 0;
    &:before {
      opacity: 1;
    }
  }

  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    background: rgb(37, 140, 138);
    background: linear-gradient(
      90deg,
      rgba(37, 140, 138, 1) 0%,
      rgba(37, 140, 138, 1) 100%
    );
  }

  &:active {
    border: 0;
  }
  &:visited {
    color: $white;
  }
}

.button {
  &--full-width {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
  }
  &--arrow {
    svg {
      display: inline-flex;
      margin-left: 0.5rem;
    }
  }
  // Foodex
  &--2 {
    background: rgb(47, 181, 179);
    background: linear-gradient(
      90deg,
      rgba(47, 181, 179, 1) 0%,
      rgba(37, 140, 138, 1) 100%
    );
    &:before {
      background: rgb(37, 140, 138);
      background: linear-gradient(
        90deg,
        rgba(37, 140, 138, 1) 0%,
        rgba(37, 140, 138, 1) 100%
      );
    }
  }
  // NCS
  &--3 {
    background: rgb(62, 180, 255);
    background: linear-gradient(
      90deg,
      rgba(62, 180, 255, 1) 0%,
      rgba(26, 132, 199, 1) 100%
    );
    &:before {
      background: rgb(26, 132, 199);
      background: linear-gradient(
        90deg,
        rgba(26, 132, 199, 1) 0%,
        rgba(26, 132, 199, 1) 100%
      );
    }
  }
  // Food & Drink
  &--4 {
    background: rgb(255, 47, 100);
    background: linear-gradient(
      90deg,
      rgba(255, 47, 100, 1) 0%,
      rgba(214, 0, 54, 1) 100%
    );
    &:before {
      background: rgb(214, 0, 54);
      background: linear-gradient(
        90deg,
        rgba(214, 0, 54, 1) 0%,
        rgba(214, 0, 54, 1) 100%
      );
    }
  }
  // Farm Shop
  &--5 {
    background: rgb(124, 65, 130);
    background: linear-gradient(
      90deg,
      rgba(124, 65, 130, 1) 0%,
      rgba(159, 1, 175, 1) 100%
    );
    &:before {
      background: rgb(124, 65, 130);
      background: linear-gradient(
        90deg,
        rgba(124, 65, 130, 1) 0%,
        rgba(124, 65, 130, 1) 100%
      );
    }
  }
  // Forecourt
  &--6 {
    background: rgb(0, 230, 87);
    background: linear-gradient(
      90deg,
      rgba(0, 230, 87, 1) 0%,
      rgba(0, 174, 66, 1) 100%
    );
    &:before {
      background: rgb(0, 174, 66);
      background: linear-gradient(
        90deg,
        rgba(0, 174, 66, 1) 0%,
        rgba(0, 174, 66, 1) 100%
      );
    }
  }
  // TRS
  &--7 {
    background: rgb(87, 87, 76);
    background: linear-gradient(
      90deg,
      rgba(87, 87, 76, 1) 0%,
      rgba(29, 29, 27, 1) 100%
    );
    &:before {
      background: rgb(87, 87, 76);
      background: linear-gradient(
        90deg,
        rgba(29, 29, 27, 1) 0%,
        rgba(29, 29, 27, 1) 100%
      );
    }
  }
  &-wrap {
    &--center {
      text-align: center;
    }
  }
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
