h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $base-heading-font-family;
  line-height: $base-heading-line-height;
  font-weight: $base-heading-font-weight;
  margin: 1.5rem 0;
  text-transform: uppercase;
}
h1,
.h1 {
  font-size: $base-h1-font-size;
}
h2,
.h2 {
  font-size: $base-h2-font-size;
}
h3,
.h3 {
  font-size: $base-h3-font-size;
}
h4,
.h4 {
  font-size: $base-h4-font-size;
}
h5,
.h5 {
  font-size: $base-h5-font-size;
}
h6,
.h6 {
  font-size: $base-h6-font-size;
}
